import React, {Component} from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";

import {
  Box,
  Image,
  Avatar,
  Anchor,
  Text,
  TextInput,
  Collapsible,
  Button,
  ResponsiveContext
} from 'grommet';

import { FormSearch, Logout } from 'grommet-icons';

import { Config } from '../../Config';
import ApiToken, { apiconn } from '../../apiconn'; //axios connectors are here

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppBar = (props) => (
  <Box
    tag='header'
    {...props}
  />
);

const AppBarUser = (props) => (
  <Box
    tag='header'
    {...props}
  />
);

const AppBarUserNoMenu = (props) => (
  <Box
    tag='header'
    {...props}
  />
);

var navstate = null;
var menubutton = false;

var timercount = 1;

const Header = () => {
  return (
    <AppBar width="100%">
      <Box>
        <Image
          full="horizontal"
          fit="contain"
          src="logo.png"
        />
      </Box>
  </AppBar>
  );
};

class HeaderUserClass extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.login.id_user == 1)
      this.doLogout();
    
    if(this.props.user.data.accessobj === undefined){
      this.props.user.data.accessobj = [];
    }
    
    if(this.props.login.id_user < 1)
        this.props.history.replace('/');
    
    if(this.props.navstate !== undefined){
        navstate = this.props.navstate;
    }    
    
    if(this.props.menubutton !== undefined){
        menubutton = this.props.menubutton;
    }
  }
  
  doLogout = () => {
    this.props.saveLogin(0, '');
    this.props.history.replace('/');
  };
  
  componentDidMount(){
      if(this.props.login.id_user == 1 || this.props.login.id_user == 0)
        this.doLogout();
    
      this._fetchUserData();
      this.thetimer = setInterval(() => { this._timerFunctions() }, 2500);
  }
  
  componentWillUnmount(){
      clearInterval(this.thetimer);
  }
  
  async _fetchUserData(){
    if(this.props.login.id_user < 1){
        this.doLogout();
    }
    // else if(this.props.login.id_user < 4 && 1 == 2){
    //     let access = ["master_data", "catalog", "show_capital_price", "purchase_order_all", "purchase_order_receipt", "marketing", "sales_order_all", "sales_order_shipping", "sales_chart_statistics", "complaint", "complaint_shipping", "finance_all", "finance_crosscheck_ongkir_cash", "finance_bank_operational", "warehouse", "reports_all", "reports_admin", "pro_master_data", "pro_items", "pro_purchase_order_all", "pro_purchase_order_receipt", "pro_batch_all", "pro_batch_parts_input", "pro_batch_parts_output", "pro_assembly", "pro_qc", "pro_packing", "pro_surat_jalan", "pro_warehouse", "pro_finance"];
    //     
    //     let username = "upspace";
    //     
    //     if(this.props.login.id_user == 2){
    //       access = ["purchase_order_receipt", "sales_order_shipping", "complaint_shipping"];
    //       
    //       username = "semarang";
    //     }
    //     
    //     let resp = {
    //       id_user: this.props.login.id_user,
    //       username: username,
    //       email: "",
    //       access: "",
    //       accessobj: access,
    //     };
    //     
    //     this.props.fetchUser(resp);
    //     
    // }
    else {
      apiconn.getApi.get('/user/'+this.props.login.id_user, {headers: { Authorization: this.props.login.api_token !== undefined ? "Bearer " + this.props.login.api_token : "" }})
        .then((response) => {
          if (response.status >= 200 && response.status <= 202) { //success
            let resp = response.data;
            
            if(resp.id_user == 3){
               // let access = [
               //   "show_capital_price",
               //   "sales_chart_statistics",
               //   "finance_bank_operational",
               //   "approve_b_to_b",
               //   "stock_opname",
               //   "master_customer",
               //   "master_supplier",
               //   "master_category",
               //   "master_unit",
               //   "master_warehouse",
               //   "master_courier",
               //   "master_marketing_type",
               //   "master_marketing_expense",
               //   "master_work_report_template",
               //   "master_account",
               //   "master_coa",
               //   "master_expense_post",
               //   "catalog_product",
               //   "catalog_product_website_only",
               //   "catalog_packaging_material",
               //   "catalog_consumable",
               //   "package_sku",
               //   "purchase_order_all",
               //   "purchase_order_receipt",
               //   "sales_order_all",
               //   "sales_order_shipping",
               //   "surat_jalan",
               //   "complaint",
               //   "complaint_shipping",
               //   "warehouse",
               //   "warehouse_low_stock",
               //   "inventory_aging_report",
               //   "warehouse_item_report",
               //   "marketing",
               //   "finance_transaction_bank",
               //   "finance_transaction_marketplace",
               //   "finance_transaction_courier",
               //   "finance_transaction_cash",
               //   "finance_adjustment_list",
               //   "finance_crosscheck_ongkir_cash",
               //   "finance_crosscheck_marketplace_expense",
               //   "finance_data_marketplace_expense",
               //   "finance_fixed_assets",
               //   "finance_value_per_warehouse",
               //   "finance_ledger",
               //   "work_report",
               //   "report_stock_sheet_with_value",
               //   "report_sales",
               //   "report_product_omzet",
               //   "report_sales_per_periode",
               //   "report_product_average",
               //   "report_marketing",
               //   "report_retur",
               //   "report_consumable",
               //   "report_ayat_silang",
               //   "report_accounting",
               //   "report_accounting_expense_without_salary",
               //   "web_article",
               //   "web_category",
               //   "web_banner",
               //   "web_voucher",
               //   "pro_master_supplier",
               //   "pro_master_unit",
               //   "pro_master_account",
               //   "pro_master_expense",
               //   "pro_master_outsource",
               //   "pro_items_material",
               //   "pro_items_part",
               //   "pro_items_packaging",
               //   "pro_items_consumable",
               //   "pro_items_product",
               //   "pro_purchase_order_all",
               //   "pro_purchase_order_receipt",
               //   "pro_batch_all",
               //   "pro_batch_parts_input",
               //   "pro_batch_parts_output",
               //   "pro_assembly",
               //   "pro_qc",
               //   "pro_packing",
               //   "pro_surat_jalan",
               //   "pro_stocksheet_material",
               //   "pro_stocksheet_part",
               //   "pro_stocksheet_packaging",
               //   "pro_stocksheet_consumable",
               //   "pro_stocksheet_product",
               //   "pro_stocksheet_outsource",
               //   "pro_sales_order",
               //   "pro_finance_bank",
               //   "pro_finance_cash",
               //   "pro_report_stocksheet_value",
               //   "pro_report_production",
               //   "pro_report_consumable",
               // ];
               
               let access = [
                 "pro_master_unit",
                 "pro_items_material",
                 "pro_items_part",
                 "pro_items_packaging",
                 "pro_items_consumable",
                 "pro_items_product",
                 "pro_master_supplier",
                 "sales_order_all",
                 "master_customer",
                 "master_courier",
                 "master_account",
                 "master_coa",
                 "master_expense_post",
                 "pro_purchase_order_all",
                 "pro_batch_all",
                 "pro_master_outsource",
                 "pro_stocksheet_material",
                 "pro_stocksheet_part",
                 "pro_stocksheet_packaging",
                 "pro_stocksheet_consumable",
                 "pro_stocksheet_product",
                 "pro_stocksheet_outsource",
                 "finance_transaction_bank",
                 "finance_transaction_cash",
                 "surat_jalan",
                 "invoice",
                 "pro_report_production",
                 "pro_report_production_with_capital",
                 "pro_report_production_summary",
                 "report_accounting",
                 "show_purchase_price",
                 "show_sale_price",
                 "pro_report_consumable",
                 "report_product_average",
                 "pro_report_profit_per_batch",
               ]
               
               resp.accessobj = access;
             }
            
            if(resp.deleted == 1){ //logout if user is deleted
                this.doLogout();
            }
            else {
                this.props.fetchUser(resp);
            }
        }
        else if(response.status == 203){ //fail
          let resp = response.data;
          
          //this.props.history.replace("/");
        }
        else{
          //console.log("Request failed");
        }
      })
      .catch(error => {
        if(error !== undefined){
          if(error.response !== undefined){
            if(error.response.status !== undefined){
              if(error.response.status == 401){ //unauthorized
                this.doLogout();
              }
            }
          }
        }
        
        //this._backgroundFetchAttempt();
        });
    }
  }
  
  _timerFunctions(){
      this._fetchUserData();
      
      timercount++;
      if(timercount > 60)
        timercount = 1;
  }
  
  toastContainer(){
    return (
      <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
    )
  }
  
  render(){
    if(menubutton){
        return (
          <ResponsiveContext.Consumer>
          {size => (
            <AppBarUser
              direction='row'
              fill='horizontal'
              align='center'
              justify='between'
              pad={size==="small" ? ({ right:"large"}) : ({horizontal:"large", vertical:"none"})}
              style={{ zIndex: '2' }}
            >
              <Box className="header-search" width={size==="small" ? "80%" : "35%"}>
                { /*<TextInput
                  placeholder="Search"
                  icon={<FormSearch />}
                  reverse="true"
                  size="small"
                />*/ }
                { this.toastContainer() }
              </Box>
              <Box direction="row" gap="small" align="center">
                <Box className="options-group" direction="row" align="center">
                  <Anchor href="#">
                    <Avatar src="avatar.png" size="35px" />
                  </Anchor>
                  {size !== 'small' && (
                    <Anchor href="#">
                      <Text size="small" margin={{left:"10px"}}>{this.props.user.data.username}</Text>
                    </Anchor>
                  )}
                  <Box className="options-list slideinright" width={size==="small" ? "130px" : "170px"} align={size==="small" ? "end" : "start"} background="content" pad={size==="small" ? "medium" : "small"} round="xsmall">
                    <Anchor href="#" icon={<Logout size="small" color="alert" />} onClick={() => { this.doLogout(); } } label="Logout" color="text" size="xsmall" />
                  </Box>
                </Box>
              </Box>
            </AppBarUser>
          )}
          </ResponsiveContext.Consumer>
          )
      } else {
        return (
            <AppBarUserNoMenu alignSelf="center">
              
            </AppBarUserNoMenu>
          )
      }
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: userdata => {
      dispatch(fetchUser(userdata));
    },
  };
};

const HeaderUser = connect(mapStateToProps, mapDispatchToProps)(HeaderUserClass);
export {Header, HeaderUser};
