import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppRoute from "./helpers/AppRoute";

import { DefaultLayout } from "./layouts";
//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";

const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
//const Forgot = lazy(() => import("./pages/Forgot"));
const Home = lazy(() => import("./pages/Home"));
const MasterCustomer = lazy(() => import("./pages/MasterCustomer"));
const MasterSupplier = lazy(() => import("./pages/MasterSupplier"));
const MasterProdSupplier = lazy(() => import("./pages/MasterProdSupplier"));
const MasterTest = lazy(() => import("./pages/MasterTest"));
const MasterItem = lazy(() => import("./pages/MasterItem"));
const MasterUnit = lazy(() => import("./pages/MasterUnit"));
const MasterMarketingType = lazy(() => import("./pages/MasterMarketingType"));
const MasterExpenseDescription = lazy(() => import("./pages/MasterExpenseDescription"));
const MasterCourier = lazy(() => import("./pages/MasterCourier"));
const MasterWarehouse = lazy(() => import("./pages/MasterWarehouse"));
const ProductionWarehouse = lazy(() => import("./pages/ProductionWarehouse"));
const MasterCategory = lazy(() => import("./pages/MasterCategory"));
const MasterAccount = lazy(() => import("./pages/MasterAccount"));
const MasterCoa = lazy(() => import("./pages/MasterCoa"));
const MasterUser = lazy(() => import("./pages/MasterUser"));
const MasterExpense = lazy(() => import("./pages/MasterExpense"));
const MasterProdExpense = lazy(() => import("./pages/MasterProdExpense"));
const Transaction = lazy(() => import("./pages/Transaction"));
const SalesOrderDetail = lazy(() => import("./pages/SalesOrderDetail"));
const SalesOrder = lazy(() => import("./pages/SalesOrder"));
const PurchaseOrder = lazy(() => import("./pages/PurchaseOrder"));
const PurchaseOrderDetail = lazy(() => import("./pages/PurchaseOrderDetail"));
const Complaint = lazy(() => import("./pages/Complaint"));
const ComplaintDetail = lazy(() => import("./pages/ComplaintDetail"));
const MasterProductionItem = lazy(() => import("./pages/MasterProductionItem"));
const ProductionBatchDetail = lazy(() => import("./pages/ProductionBatchDetail"));
const ProductionBatch = lazy(() => import("./pages/ProductionBatch"));
const MasterProduct = lazy(() => import("./pages/MasterProduct"));
const Settings = lazy(() => import("./pages/Settings"));
const ReportOngkirCash = lazy(() => import("./pages/ReportOngkirCash"));
const ReportMarketplace = lazy(() => import("./pages/ReportMarketplace"));
const ReportMarketplaceTrx = lazy(() => import("./pages/ReportMarketplaceTrx"));
const ReportSales = lazy(() => import("./pages/ReportSales"));
const ReportSalesPackage = lazy(() => import("./pages/ReportSalesPackage"));
const ReportSalesPeriod = lazy(() => import("./pages/ReportSalesPeriod"));
const ReportStockSheet = lazy(() => import("./pages/ReportStockSheet"));
const ReportAgingStock = lazy(() => import("./pages/ReportAgingStock"));
const ReportStockValue = lazy(() => import("./pages/ReportStockValue"));
const ReportJasaTukang = lazy(() => import("./pages/ReportJasaTukang"));
const SuratJalan = lazy(() => import("./pages/SuratJalan"));
const Marketing = lazy(() => import("./pages/Marketing"));
const MarketingDetail = lazy(() => import("./pages/MarketingDetail"));
const ProductionStockSheet = lazy(() => import("./pages/ProductionStockSheet"));
const ProductionSuratJalan = lazy(() => import("./pages/ProductionSuratJalan"));
const MasterProductionUnit = lazy(() => import("./pages/MasterProductionUnit"));
const MasterProductionAccount = lazy(() => import("./pages/MasterProductionAccount"));
const ProductionTransaction = lazy(() => import("./pages/ProductionTransaction"));
const ProdPurchaseOrder = lazy(() => import("./pages/ProdPurchaseOrder"));
const ProdPurchaseOrderDetail = lazy(() => import("./pages/ProdPurchaseOrderDetail"));
const MasterHoliday = lazy(() => import("./pages/MasterHoliday"));
const ReportProductAverage = lazy(() => import("./pages/ReportProductAverage"));
//const World = lazy(() => import("./pages/World"));
const ProdSalesOrder = lazy(() => import("./pages/ProdSalesOrder"));
const ProdSalesOrderDetail = lazy(() => import("./pages/ProdSalesOrderDetail"));
const ReportMarketing = lazy(() => import("./pages/ReportMarketing"));
const ReportRetur = lazy(() => import("./pages/ReportRetur"));
const ReportConsumable = lazy(() => import("./pages/ReportConsumable"));
const ItemsToPrepare = lazy(() => import("./pages/ItemsToPrepare"));
const ProductionStockSheetValue = lazy(() => import("./pages/ProductionStockSheetValue"));
const ReportAccounting = lazy(() => import("./pages/ReportAccounting"));
const ReportProduction = lazy(() => import("./pages/ReportProduction"));
const ReportProductionSummary = lazy(() => import("./pages/ReportProductionSummary"));
const MasterAsset = lazy(() => import("./pages/MasterAsset"));
const WarehouseValue = lazy(() => import("./pages/WarehouseValue"));
const ReportItemPeriod = lazy(() => import("./pages/ReportItemPeriod"));
const MasterWorkTemplate = lazy(() => import("./pages/MasterWorkTemplate"));
const WorkReport = lazy(() => import("./pages/WorkReport"));
const MasterArticle = lazy(() => import("./pages/MasterArticle"));
const Ledger = lazy(() => import("./pages/Ledger"));
const WebCategory = lazy(() => import("./pages/WebCategory"));
const WebBanner = lazy(() => import("./pages/WebBanner"));
const WebVoucher = lazy(() => import("./pages/WebVoucher"));
const MasterPackage = lazy(() => import("./pages/MasterPackage"));
const ReportProdConsumable = lazy(() => import("./pages/ReportProdConsumable"));
const ReportItemWarehouse = lazy(() => import("./pages/ReportItemWarehouse"));
const ReportProdAccounting = lazy(() => import("./pages/ReportProdAccounting"));
const MasterWalkin = lazy(() => import("./pages/MasterWalkin"));
const SalesOrderWalkin = lazy(() => import("./pages/SalesOrderWalkin"));
const SalesOrderDetailWalkin = lazy(() => import("./pages/SalesOrderDetailWalkin"));
const MasterPromo = lazy(() => import("./pages/MasterPromo"));
const ReportComplaint = lazy(() => import("./pages/ReportComplaint"));
const ReportWarehouse = lazy(() => import("./pages/ReportWarehouse"));
const ReportAyatSilang = lazy(() => import("./pages/ReportAyatSilang"));
const MasterAccs = lazy(() => import("./pages/MasterAccs"));
const Invoice = lazy(() => import("./pages/Invoice"));
const ReportProfitPerBatch = lazy(() => import("./pages/ReportProfitPerBatch"));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={Login}
            />
            <Route
              path={process.env.PUBLIC_URL + "/signup"}
              component={Signup}
            />
            <Route
              path={process.env.PUBLIC_URL + "/home"}
              /*component={Home}*/
              render={props => <Home {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/customer"}
              /*component={Home}*/
              render={props => <MasterCustomer {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/supplier"}
              /*component={Home}*/
              render={props => <MasterSupplier {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/unit"}
              /*component={Home}*/
              render={props => <MasterUnit {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/marketingtype"}
              /*component={Home}*/
              render={props => <MasterMarketingType {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/expensedescription"}
              /*component={Home}*/
              render={props => <MasterExpenseDescription {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/warehouse"}
              /*component={Home}*/
              render={props => <MasterWarehouse {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productionwarehouse"}
              /*component={Home}*/
              render={props => <ProductionWarehouse {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/courier"}
              /*component={Home}*/
              render={props => <MasterCourier {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/category"}
              /*component={Home}*/
              render={props => <MasterCategory {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/account"}
              /*component={Home}*/
              render={props => <MasterAccount {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/coa"}
              /*component={Home}*/
              render={props => <MasterCoa {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/expense"}
              /*component={Home}*/
              render={props => <MasterExpense {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/prodexpense"}
              /*component={Home}*/
              render={props => <MasterProdExpense {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/item"}
              /*component={Home}*/
              render={props => <MasterItem {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/salesorderdetail"}
              /*component={Add}*/
              render={props => <SalesOrderDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/salesorder"}
              /*component={Add}*/
              render={props => <SalesOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/complaint"}
              /*component={Add}*/
              render={props => <Complaint {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/complaintdetail"}
              /*component={Add}*/
              render={props => <ComplaintDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterprodsupplier"}
              /*component={Add}*/
              render={props => <MasterProdSupplier {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterproductionitem"}
              /*component={Add}*/
              render={props => <MasterProductionItem {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productionbatchdetail"}
              /*component={Add}*/
              render={props => <ProductionBatchDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productionbatch"}
              /*component={Add}*/
              render={props => <ProductionBatch {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterproduct"}
              /*component={Add}*/
              render={props => <MasterProduct {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/transaction"}
              /*component={Add}*/
              render={props => <Transaction {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/settings"}
              /*component={Add}*/
              render={props => <Settings {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportongkircash"}
              /*component={Add}*/
              render={props => <ReportOngkirCash {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportmarketplacetrx"}
              /*component={Add}*/
              render={props => <ReportMarketplaceTrx {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportmarketplace"}
              /*component={Add}*/
              render={props => <ReportMarketplace {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportsales"}
              /*component={Add}*/
              render={props => <ReportSales {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportsalespackage"}
              /*component={Add}*/
              render={props => <ReportSalesPackage {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportsalesperiod"}
              /*component={Add}*/
              render={props => <ReportSalesPeriod {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/mastertest"}
              /*component={Add}*/
              render={props => <MasterTest {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/stocksheet"}
              /*component={Add}*/
              render={props => <ReportStockSheet {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/agingstock"}
              /*component={Add}*/
              render={props => <ReportAgingStock {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/stockvalue"}
              /*component={Add}*/
              render={props => <ReportStockValue {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/jasatukang"}
              /*component={Add}*/
              render={props => <ReportJasaTukang {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/suratjalan"}
              /*component={Add}*/
              render={props => <SuratJalan {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/marketing"}
              /*component={Add}*/
              render={props => <Marketing {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/marketingdetail"}
              /*component={Add}*/
              render={props => <MarketingDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productionstocksheet"}
              /*component={Add}*/
              render={props => <ProductionStockSheet {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productionsuratjalan"}
              /*component={Add}*/
              render={props => <ProductionSuratJalan {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterproductionunit"}
              /*component={Add}*/
              render={props => <MasterProductionUnit {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterproductionaccount"}
              /*component={Add}*/
              render={props => <MasterProductionAccount {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productiontransaction"}
              /*component={Add}*/
              render={props => <ProductionTransaction {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/prodpurchaseorder"}
              /*component={Add}*/
              render={props => <ProdPurchaseOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/prodpurchaseorderdetail"}
              /*component={Add}*/
              render={props => <ProdPurchaseOrderDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/purchaseorder"}
              /*component={Add}*/
              render={props => <PurchaseOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/purchaseorderdetail"}
              /*component={Add}*/
              render={props => <PurchaseOrderDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masteruser"}
              /*component={Add}*/
              render={props => <MasterUser {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterholiday"}
              /*component={Add}*/
              render={props => <MasterHoliday {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportproductaverage"}
              /*component={Add}*/
              render={props => <ReportProductAverage {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/prodsalesorder"}
              /*component={Add}*/
              render={props => <ProdSalesOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/prodsalesorderdetail"}
              render={props => <ProdSalesOrderDetail {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportmarketing"}
              render={props => <ReportMarketing {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportretur"}
              render={props => <ReportRetur {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportconsumable"}
              render={props => <ReportConsumable {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/itemstoprepare"}
              render={props => <ItemsToPrepare {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/productionstocksheetvalue"}
              render={props => <ProductionStockSheetValue {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportaccounting"}
              render={props => <ReportAccounting {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportproduction"}
              render={props => <ReportProduction {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportproductionsummary"}
              render={props => <ReportProductionSummary {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterasset"}
              render={props => <MasterAsset {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/warehousevalue"}
              render={props => <WarehouseValue {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportitemperiod"}
              render={props => <ReportItemPeriod {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterworktemplate"}
              render={props => <MasterWorkTemplate {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/workreport"}
              render={props => <WorkReport {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterarticle"}
              render={props => <MasterArticle {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/ledger"}
              render={props => <Ledger {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/webcategory"}
              render={props => <WebCategory {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/webbanner"}
              render={props => <WebBanner {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/webvoucher"}
              render={props => <WebVoucher {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterpackage"}
              render={props => <MasterPackage {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportprodconsumable"}
              render={props => <ReportProdConsumable {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportitemwarehouse"}
              render={props => <ReportItemWarehouse {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportprodaccounting"}
              render={props => <ReportProdAccounting {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterwalkin"}
              render={props => <MasterWalkin {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/salesorderwalkin"}
              render={props => <SalesOrderWalkin {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/salesorderdetailwalkin"}
              render={props => <SalesOrderDetailWalkin {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masterpromo"}
              render={props => <MasterPromo {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportcomplaint"}
              render={props => <ReportComplaint {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportwarehouse"}
              render={props => <ReportWarehouse {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportayatsilang"}
              render={props => <ReportAyatSilang {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/masteraccs"}
              render={props => <MasterAccs {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/invoice"}
              render={props => <Invoice {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reportprofitperbatch"}
              render={props => <ReportProfitPerBatch {...props} />}
            />
            { /*<Route
              path={process.env.PUBLIC_URL + "/welcome"}
              component={Welcome}
            />
            <Route path={process.env.PUBLIC_URL + "/login"} component={Login} />
            
            <AppRoute
              path={process.env.PUBLIC_URL + "/shop"}
              component={Shop}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/product/:id"}
              component={Product}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/chat"}
              component={Chat}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/cart"}
              component={Cart}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/wishlist"}
              component={Wishlist}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/checkout"}
              component={Checkout}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/not-found"}
              component={NotFound}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/search"}
              component={Search}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/profile"}
              component={Profile}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/edit-profile"}
              component={EditProfile}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/notification"}
              component={Notification}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/contact"}
              component={Contact}
              layout={DefaultLayout}
            />
            <AppRoute
              path={process.env.PUBLIC_URL + "/order"}
              component={Order}
              layout={DefaultLayout}
            />
            <AppRoute exact component={NotFound} layout={DefaultLayout} /> */ }
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
