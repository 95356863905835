import React, {Component} from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import {Navigation} from 'react-minimal-side-navigation';
import '../../components/react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import {
  Anchor,
  Box,
  Image,
  Text,
  Tabs,
  Tab
} from 'grommet';
import { Dashboard, Basket, CatalogOption, Folder, Ad, Money, BarChart, Configure, Clipboard, Logout, Flows, Cubes, Deliver, Menu, Language } from 'grommet-icons';

var navstate = null;

const arrProdPages = [
  "/productionbatch",
  "/productionbatchdetail",
  "/production",
  "/masterproductionitem",
  "/masterproductionunit",
  "/masterproductionaccount",
  "/masterprodsupplier",
  "/masterproduct",
  "/productionstocksheet" ,
  "/productionstocksheetvalue" ,
  "/productionsuratjalan" ,
  "/productiontransaction",
  "/prodpurchaseorder",
  "/prodpurchaseorderdetail",
  "/prodexpense",
  "/prodsalesorder",
  "/prodsalesorderdetail",
  "/reportproduction",
  "/reportprodconsumable",
  "/reportprodaccounting",
]

class SidebarMenu extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.user.data.accessobj === undefined){
      this.props.user.data.accessobj = [];
    }
    
    if(this.props.login.id_user == 3){
      this.props.user.data.accessobj = [
        "show_capital_price",
        "sales_chart_statistics",
        "finance_bank_operational",
        "approve_b_to_b",
        "stock_opname",
        "master_customer",
        "master_supplier",
        "master_category",
        "master_unit",
        "master_warehouse",
        "master_courier",
        "master_marketing_type",
        "master_marketing_expense",
        "master_work_report_template",
        "master_account",
        "master_coa",
        "master_expense_post",
        "master_walkin_outlet",
        "catalog_product",
        "catalog_product_website_only",
        "catalog_packaging_material",
        "catalog_consumable",
        "package_sku",
        "purchase_order_all",
        "purchase_order_receipt",
        "sales_order_all",
        "sales_order_shipping",
        "sales_order_walkin",
        "surat_jalan",
        "complaint",
        "complaint_shipping",
        "warehouse",
        "warehouse_low_stock",
        "inventory_aging_report",
        "warehouse_item_report",
        "marketing",
        "marketing_promo",
        "finance_transaction_bank",
        "finance_transaction_marketplace",
        "finance_transaction_courier",
        "finance_transaction_cash",
        "finance_adjustment_list",
        "finance_crosscheck_ongkir_cash",
        "finance_crosscheck_marketplace_expense",
        "finance_data_marketplace_expense",
        "finance_fixed_assets",
        "finance_value_per_warehouse",
        "finance_ledger",
        "work_report",
        "report_stock_sheet_with_value",
        "report_sales",
        "report_product_omzet",
        "report_sales_per_periode",
        "report_product_average",
        "report_marketing",
        "report_retur",
        "report_complaint",
        "report_consumable",
        "report_ayat_silang",
        "report_accounting",
        "report_accounting_expense_without_salary",
        "web_article",
        "web_category",
        "web_banner",
        "web_voucher",
        "pro_master_supplier",
        "pro_master_unit",
        "pro_master_account",
        "pro_master_expense",
        "pro_master_outsource",
        "pro_items_material",
        "pro_items_part",
        "pro_items_packaging",
        "pro_items_consumable",
        "pro_items_product",
        "pro_purchase_order_all",
        "pro_purchase_order_receipt",
        "pro_batch_all",
        "pro_batch_parts_input",
        "pro_batch_parts_output",
        "pro_assembly",
        "pro_qc",
        "pro_packing",
        "pro_surat_jalan",
        "pro_stocksheet_material",
        "pro_stocksheet_part",
        "pro_stocksheet_packaging",
        "pro_stocksheet_consumable",
        "pro_stocksheet_product",
        "pro_stocksheet_outsource",
        "pro_sales_order",
        "pro_finance_bank",
        "pro_finance_cash",
        "pro_report_stocksheet_value",
        "pro_report_production",
        "pro_report_production_with_capital",
        "pro_report_production_summary",
        "pro_report_consumable",
        "pro_report_profit_per_batch",
      ];
      
      this.props.user.data.accessobj = [
        "pro_master_unit",
        "pro_items_material",
        "pro_items_part",
        "pro_items_packaging",
        "pro_items_consumable",
        "pro_items_product",
        "pro_master_supplier",
        "sales_order_all",
        "master_customer",
        "master_courier",
        "master_account",
        "master_coa",
        "master_expense_post",
        "pro_purchase_order_all",
        "pro_batch_all",
        "pro_master_outsource",
        "pro_stocksheet_material",
        "pro_stocksheet_part",
        "pro_stocksheet_packaging",
        "pro_stocksheet_consumable",
        "pro_stocksheet_product",
        "pro_stocksheet_outsource",
        "finance_transaction_bank",
        "finance_transaction_cash",
        "surat_jalan",
        "invoice",
        "pro_report_production",
        "pro_report_production_with_capital",
        "pro_report_production_summary",
        "report_accounting",
        "show_purchase_price",
        "show_sale_price",
        "pro_report_consumable",
        "report_product_average",
        "pro_report_profit_per_batch",
      ];
    }
    
    this.state = {
      activeTab: 0,
      activeMenu: "",
      menuretail: [],
      menupro: [],
      accessobj: this.props.user.data.accessobj,
    }
    
    if(this.props.navstate !== undefined){
        navstate = this.props.navstate;
    }    
        
  }
  
  componentDidMount(){
    /*console.log(arrProdPages);
    console.log(window.location.pathname);
    console.log(arrProdPages.indexOf(window.location.pathname.toString()));
    console.log(this.state.activeTab);*/
    
    //console.log(this.state.activeTab);
    let activeMenu = this.props.history.location.pathname + this.props.history.location.search;
    if(activeMenu.toString().length > 1)
      this.setState({activeMenu: activeMenu});
    // if(arrProdPages.indexOf((this.props.history.location.pathname)) > -1){
    //   this.setState({activeTab: 1});
    // }
    
    let menuretail = [];
    let menupro = [];
    
    menuretail.push({
      title: 'Dashboard',
      itemId: '/home',
      // you can use your own custom Icon component as well
      // icon is optional
      elemBefore: () => <Dashboard />,
    },);
    
    let subnav = [];
    
    // if(this.state.accessobj.includes("master_customer")){
    //   subnav.push(
    //     {
    //       title: 'Customer',
    //       itemId: '/customer',
    //     }
    //   );
    //   
    //   /*subnav.push(
    //     {
    //       title: 'Customer (Walk In)',
    //       itemId: '/customer?type=walkin',
    //     }
    //   );*/
    // }
    
    // if(this.state.accessobj.includes("master_supplier")){
    //   subnav.push(
    //     {
    //       title: 'Supplier',
    //       itemId: '/supplier',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_category")){
    //   subnav.push(
    //     {
    //       title: 'Product Category',
    //       itemId: '/category',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_unit")){
    //   subnav.push(
    //     {
    //       title: 'Unit',
    //       itemId: '/unit',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_warehouse")){
    //   subnav.push(
    //     {
    //       title: 'Warehouse',
    //       itemId: '/warehouse',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_courier")){
    //   subnav.push(
    //     {
    //       title: 'Courier',
    //       itemId: '/courier',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_marketing_type")){
    //   subnav.push(
    //     {
    //       title: 'Marketing Type',
    //       itemId: '/marketingtype',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_marketing_expense")){
    //   subnav.push(
    //     {
    //       title: 'Marketing - Expense Description',
    //       itemId: '/expensedescription',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("master_work_report_template")){
    //   subnav.push(
    //     {
    //       title: 'Work Report Template',
    //       itemId: '/masterworktemplate',
    //     }
    //   );
    // }
    // 
    // 
    // if(this.state.accessobj.includes("master_walkin_outlet")){
    //   subnav.push(
    //     {
    //       title: 'Walk In Outlet',
    //       itemId: '/masterwalkin',
    //     }
    //   );
    // }
    //  
    // if(subnav.length > 0){ 
    //   menuretail.push({
    //     title: 'Master Data',
    //     itemId: 'b',
    //     elemBefore: () => <Folder />,
    //     subNav: subnav
    //   });
    //   
    //   subnav = [];
    // }
    // 
    // if(this.state.accessobj.includes("catalog_product") || this.state.accessobj.includes("catalog_product_website_only")){
    //   subnav.push(
    //     {
    //       title: 'Product',
    //       itemId: '/item?type=product',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("catalog_packaging_material")){
    //   subnav.push(
    //     {
    //       title: 'Packaging Material',
    //       itemId: '/item?type=packaging material',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("catalog_consumable")){
    //   subnav.push(
    //     {
    //       title: 'Consumable',
    //       itemId: '/item?type=consumable',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("package_sku")){
    //   subnav.push(
    //     {
    //       title: 'Package with SKU',
    //       itemId: '/masterpackage',
    //     }
    //   );
    // }
    // 
    // if(subnav.length > 0){ 
    //   menuretail.push({
    //     title: 'Catalogue',
    //     itemId: 'a',
    //     elemBefore: () => <CatalogOption />,
    //     subNav: subnav
    //   });
    //   
    //   subnav = [];
    // }
    // 
    // 
    // 
    // if(this.state.accessobj.includes("purchase_order_all") || this.state.accessobj.includes("purchase_order_receipt")){
    //   menuretail.push(
    //     {
    //       title: 'Purchase Order',
    //       itemId: '/purchaseorder',
    //       elemBefore: () => <Deliver />,
    //     }
    //   );
    // }
    // 
    // 
    
    subnav = [];
    if(this.state.accessobj.includes("sales_order_all") || this.state.accessobj.includes("sales_order_shipping")){
      subnav.push(
        {
          title: 'Sales Order',
          itemId: '/salesorder',
        }
      );
    }
    // 
    // if(this.state.accessobj.includes("sales_order_walkin")){
    //   subnav.push(
    //     {
    //       title: 'Sales Order Walk In',
    //       itemId: '/salesorderwalkin',
    //     }
    //   );
    // }
    // 
    if(this.state.accessobj.includes("surat_jalan")){
      subnav.push(
        {
          title: 'Surat Jalan',
          itemId: '/suratjalan',
        }
      );
    }
    if(this.state.accessobj.includes("invoice")){
      subnav.push(
        {
          title: 'Invoice',
          itemId: '/invoice',
        }
      );
    }
    // 
    // if(this.state.accessobj.includes("complaint") || this.state.accessobj.includes("complaint_shipping")){
    //   subnav.push(
    //     {
    //       title: 'Complaint',
    //       itemId: '/complaint',
    //     }
    //   );
    // }
    // 
    if(subnav.length > 0){ 
      menuretail.push({
        title: 'Sales',
        itemId: 'n',
        elemBefore: () => <Basket />,
        subNav: subnav
      });
      
      subnav = [];
    }
    // 
    // 
    // if(this.state.accessobj.includes("warehouse")){
    //   subnav.push(
    //     {
    //       title: 'Stock Sheet',
    //       itemId: '/stocksheet?type=product&low=',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("warehouse")){
    //   subnav.push(
    //     {
    //       title: 'Stock Per Warehouse',
    //       itemId: '/reportwarehouse',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("warehouse_low_stock")){
    //   subnav.push(
    //     {
    //       title: 'Low Stock',
    //       itemId: '/stocksheet?type=product&low=low',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("inventory_aging_report")){
    //   subnav.push(
    //     {
    //       title: 'Inventory Aging Report',
    //       itemId: '/agingstock',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("warehouse_item_report")){
    //   subnav.push(
    //     {
    //       title: 'Item Report',
    //       itemId: '/reportitemwarehouse',
    //     }
    //   );
    // }
    // 
    // if(subnav.length > 0){ 
    //   menuretail.push({
    //     title: 'Warehouse',
    //     itemId: 'l',
    //     elemBefore: () => <CatalogOption />,
    //     subNav: subnav
    //   });
    //   
    //   subnav = [];
    // }
    // 
    //   
    // 
    // if(this.state.accessobj.includes("marketing")){
    //   subnav.push(
    //     {
    //       title: 'Marketing',
    //       itemId: '/marketing',
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("marketing_promo")){
    //   subnav.push(
    //     {
    //       title: 'Promo',
    //       itemId: '/masterpromo',
    //     }
    //   );
    // }
    // 
    // if(subnav.length > 0){ 
    //   menuretail.push(
    //     {
    //       title: 'Marketing',
    //       itemId: 'x',
    //       elemBefore: () => <Ad />,
    //       subNav: subnav
    //     }
    //   );
    //   
    //   subnav = [];
    // }
    // 
    // 
    // 
    
    // 
    // if(this.state.accessobj.includes("work_report")){
    //   menuretail.push(
    //     {
    //       title: 'Working Report',
    //       itemId: '/workreport',
    //       elemBefore: () => <Clipboard />,
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_stock_sheet_with_value")){
    //   subnav.push(
    //   {
    //     title: 'Stock Sheet with Value',
    //     itemId: '/stockvalue',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_sales")){
    //   subnav.push(
    //   {
    //     title: 'Sales Report',
    //     itemId: '/reportsalesperiod', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_product_omzet")){
    //   subnav.push(
    //   {
    //     title: 'Product Omzet Report',
    //     itemId: '/reportsales', //just flip it later on, periode is like Vincent's request
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_product_omzet")){
    //   subnav.push(
    //   {
    //     title: 'Package Omzet Report',
    //     itemId: '/reportsalespackage', //just flip it later on, periode is like Vincent's request
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_sales_per_periode")){
    //   subnav.push(
    //   {
    //     title: 'Sales Per Periode',
    //     itemId: '/reportitemperiod', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_product_average")){
    //   subnav.push(
    //   {
    //     title: 'Product Average',
    //     itemId: '/reportproductaverage', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_marketing")){
    //   subnav.push(
    //   {
    //     title: 'Marketing Report',
    //     itemId: '/reportmarketing', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_retur")){
    //   subnav.push(
    //   {
    //     title: 'Retur Report',
    //     itemId: '/reportretur', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_consumable")){
    //   subnav.push(
    //   {
    //     title: 'Consumable Report',
    //     itemId: '/reportconsumable', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_complaint")){
    //   subnav.push(
    //   {
    //     title: 'Complaint Report',
    //     itemId: '/reportcomplaint', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_ayat_silang")){
    //   subnav.push(
    //   {
    //     title: 'Ayat Silang Report',
    //     itemId: '/reportayatsilang', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("report_accounting") || this.state.accessobj.includes("report_accounting_expense_without_salary")){
    //   subnav.push(
    //   {
    //     title: 'Accounting Report',
    //     itemId: '/reportaccounting', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(subnav.length > 0){ 
    //   menuretail.push({
    //   title: 'Reports',
    //   itemId: 'd',
    //   elemBefore: () => <BarChart />,
    //   subNav: subnav
    //   });
    //   
    //   subnav = [];
    // }
    // 
    
    
    // if(this.state.accessobj.includes("web_article")){
    //   subnav.push(
    //   {
    //     title: 'Web Article',
    //     itemId: '/masterarticle',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("web_category")){
    //   subnav.push(
    //   {
    //     title: 'Web Category',
    //     itemId: '/webcategory', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("web_banner")){
    //   subnav.push(
    //   {
    //     title: 'Web Banner',
    //     itemId: '/webbanner', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("web_voucher")){
    //   subnav.push(
    //   {
    //     title: 'Web Voucher',
    //     itemId: '/webvoucher', //just flip it later on
    //   }
    //   );
    // }
    // 
    // if(subnav.length > 0){ 
    //   menuretail.push({
    //     title: 'Website',
    //     itemId: 'z',
    //     elemBefore: () => <Language />,
    //     subNav: subnav
    //   });
    //   
    //   subnav = [];
    // }
    
    
    subnav = [];
    
    // if(this.state.accessobj.includes("pro_master_supplier")){
    //   subnav.push(
    //   {
    //     title: 'Supplier',
    //     itemId: '/masterprodsupplier',
    //   }
    //   );
    // }
    
    if(this.state.accessobj.includes("pro_master_unit")){
      subnav.push(
      {
        title: 'Unit',
        itemId: '/masterproductionunit',
      }
      );
    }
    
    // if(this.state.accessobj.includes("pro_master_account")){
    //   subnav.push(
    //   {
    //     
    //     title: 'Finance Account',
    //     itemId: '/masterproductionaccount',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("pro_master_expense")){
    //   subnav.push(
    //   {
    //     title: 'Finance Expense Post',
    //     itemId: '/prodexpense',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("pro_master_outsource")){
    //   subnav.push(
    //   {
    //     title: 'Outsource',
    //     itemId: '/productionwarehouse',
    //   }
    //   );
    // }
    
    if(this.state.accessobj.includes("master_customer")){
      subnav.push(
        {
          title: 'Customer',
          itemId: '/customer',
        }
      );
      
      /*subnav.push(
        {
          title: 'Customer (Walk In)',
          itemId: '/customer?type=walkin',
        }
      );*/
    }
    
    if(this.state.accessobj.includes("pro_master_supplier")){
      subnav.push(
      {
        title: 'Supplier',
        itemId: '/masterprodsupplier',
      }
      );
    }
    
    if(this.state.accessobj.includes("master_courier")){
      subnav.push(
        {
          title: 'Courier',
          itemId: '/courier',
        }
      );
    }
    
    
    
    if(this.state.accessobj.includes("master_aksesoris")){
      subnav.push(
        {
          title: 'Aksesoris',
          itemId: '/masteraccs',
        }
      );
    }
    
    if(this.state.accessobj.includes("master_account")){
      subnav.push(
        {
          title: 'Account',
          itemId: '/account',
        }
      );
    }
    
    if(this.state.accessobj.includes("master_coa")){
      subnav.push(
        {
          title: 'CoA',
          itemId: '/coa',
        }
      );
    }
    
    if(this.state.accessobj.includes("master_expense_post")){
      subnav.push(
        {
          title: 'Finance - Expense Post',
          itemId: '/expense',
        }
      );
    }
    
    if(subnav.length > 0){ 
      menupro.push({
        title: 'Master Data',
        itemId: 'g',
        elemBefore: () => <Folder />,
      subNav: subnav
      });
      
      subnav = [];
    }
    
    
    
    if(this.state.accessobj.includes("pro_items_material")){
      subnav.push(
      {
        title: 'Material',
        itemId: '/masterproductionitem?type=material',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_items_material")){
      subnav.push(
      {
        title: 'Kain',
        itemId: '/masterproductionitem?type=kain',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_items_material")){
      subnav.push(
      {
        title: 'Aksesoris',
        itemId: '/masterproductionitem?type=aksesoris',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_items_part")){
      subnav.push(
      {
        title: 'Part',
        itemId: '/masterproductionitem?type=part',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_items_packaging")){
      subnav.push(
      {
        title: 'Packaging Material',
        itemId: '/masterproductionitem?type=packaging material',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_items_consumable")){
      subnav.push(
      {
        title: 'Consumable',
        itemId: '/masterproductionitem?type=consumable',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_items_product")){
      subnav.push(
      {
        title: 'Product',
        itemId: '/masterproduct',
      }
      );
    }
    
    if(subnav.length > 0){ 
      menupro.push({
        title: 'Items',
        elemBefore: () => <Flows />,
        itemId: 'h',
      subNav: subnav
      });
      
      subnav = [];
    }
    
    
    if(this.state.accessobj.includes("pro_purchase_order_all") || this.state.accessobj.includes("pro_purchase_order_receipt")){
      menupro.push(
        {
          title: 'Purchase Order',
          itemId: '/prodpurchaseorder',
          elemBefore: () => <Menu />,
        }
      );
    }
    // 
    if(this.state.accessobj.includes("pro_batch_all") || this.state.accessobj.includes("pro_batch_parts_input") || this.state.accessobj.includes("pro_batch_parts_output") || this.state.accessobj.includes("pro_assembly") || this.state.accessobj.includes("pro_qc") || this.state.accessobj.includes("pro_packing")){
      menupro.push(
        {
          title: 'Production',
          itemId: '/productionbatch',
          elemBefore: () => <Cubes />,
        }
      );
    }
    // 
    // if(this.state.accessobj.includes("pro_surat_jalan")){
    //   menupro.push(
    //     {
    //       title: 'Surat Jalan',
    //       itemId: '/productionsuratjalan',
    //       elemBefore: () => <Deliver />,
    //     }
    //   );
    // }
    // 
    // 
    if(this.state.accessobj.includes("pro_stocksheet_material")){
      subnav.push(
      {
      title: 'Material',
      itemId: '/productionstocksheet?type=material',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_stocksheet_part")){
      subnav.push(
      {
      title: 'Part',
      itemId: '/productionstocksheet?type=part',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_stocksheet_packaging")){
      subnav.push(
      {
      title: 'Packaging Material',
      itemId: '/productionstocksheet?type=packaging material',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_stocksheet_consumable")){
      subnav.push(
      {
      title: 'Consumable',
      itemId: '/productionstocksheet?type=consumable',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_stocksheet_product")){
      subnav.push(
      {
      title: 'Product',
      itemId: '/productionstocksheet?type=product',
      }
      );
    }
    
    if(this.state.accessobj.includes("pro_stocksheet_product")){
      subnav.push(
      {
      title: 'Outsource',
      itemId: '/productionstocksheet?type=outsource',
      }
      );
    }
    
    if(subnav.length > 0){ 
      menupro.push({
        title: 'Stock Sheet',
        itemId: 'm',
        elemBefore: () => <CatalogOption />,
      subNav: subnav
      });
      
      subnav = [];
    }
    // 
    // 
    // if(this.state.accessobj.includes("pro_sales_order")){
    //   menupro.push(
    //     {
    //       title: 'Sales',
    //       itemId: 'i',
    //       elemBefore: () => <Basket />,
    //       subNav: [
    //         {
    //           title: 'Sales Order',
    //           itemId: '/prodsalesorder',
    //         },
    //       ],
    //     }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("pro_finance_bank")){
    //   subnav.push(
    //   {
    //     title: 'Transaction (Bank)',
    //     itemId: '/productiontransaction?id=1',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("pro_finance_cash")){
    //   subnav.push(
    //   {
    //     title: 'Transaction (Cash)',
    //     itemId: '/productiontransaction?id=2',
    //   }
    //   );
    // }
    
    // if(this.state.accessobj.includes("pro_finance_bank") || this.state.accessobj.includes("pro_finance_cash")){
    //   menupro.push(
    //     {
    //       title: 'Finances',
    //       itemId: 's',
    //       elemBefore: () => <Money />,
    //       subNav: subnav,
    //     }
    //   );
    //   
    //   subnav = [];
    // } 
    
    
    // if(this.state.accessobj.includes("pro_report_stocksheet_value")){
    //   subnav.push(
    //   {
    //   title: 'Stock Sheet With Value',
    //   itemId: '/productionstocksheetvalue?type=material',
    //   }
    //   );
    // }
    // 
    if(this.state.accessobj.includes("pro_report_production_summary")){
      subnav.push(
      {
      title: 'Production Summary',
      itemId: '/reportproductionsummary',
      }
      );
    }
    if(this.state.accessobj.includes("pro_report_profit_per_batch")){
      subnav.push(
      {
      title: 'Profit Per Batch',
      itemId: '/reportprofitperbatch',
      }
      );
    }
    if(this.state.accessobj.includes("pro_report_production")){
      subnav.push(
      {
      title: 'Production Report',
      itemId: '/reportproduction',
      }
      );
      subnav.push(
      {
      title: 'Report Jasa Tukang',
      itemId: '/jasatukang',
      }
      );
    }
    if(this.state.accessobj.includes("report_accounting")){
      if(this.state.accessobj.includes("report_accounting")){
        subnav.push(
        {
        title: 'Accounting Report',
        itemId: '/reportaccounting',
        }
        );
      }
    }
    // 
    if(this.state.accessobj.includes("pro_report_consumable")){
      subnav.push(
      {
      title: 'Consumable Usage Report',
      itemId: '/reportprodconsumable',
      }
      );
    }
    if(this.state.accessobj.includes("report_product_average")){
      subnav.push(
      {
        title: 'Product Average',
        itemId: '/reportproductaverage', //just flip it later on
      }
      );
    }
    // 
    // if(this.state.accessobj.includes("report_accounting")){
    //   subnav.push(
    //   {
    //   title: 'Accounting Report',
    //   itemId: '/reportprodaccounting',
    //   }
    //   );
    // }
    
    if(subnav.length > 0){ 
      menupro.push({
        title: 'Reports',
        itemId: 'u',
        elemBefore: () => <BarChart />,
      subNav: subnav
      });
      
      subnav = [];
    }
    
    if(this.state.accessobj.includes("finance_transaction_bank")){
      subnav.push(
      {
        title: 'Transaction - BANK',
        itemId: '/transaction?id=1',
      }
      );
    }
    
    // if(this.state.accessobj.includes("finance_transaction_marketplace")){
    //   subnav.push(
    //   {
    //     title: 'Transaction - MARKETPLACE',
    //     itemId: '/transaction?id=4',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("finance_transaction_courier")){
    //   subnav.push(
    //   {
    //     title: 'Transaction - COURIER',
    //     itemId: '/transaction?id=8',
    //   }
    //   );
    // }
    
    if(this.state.accessobj.includes("finance_transaction_cash")){
      subnav.push(
      {
        title: 'Transaction - CASH',
        itemId: '/transaction?id=11',
      }
      );
    }
    // 
    // if(this.state.accessobj.includes("finance_adjustment_list")){
    //   subnav.push(
    //   {
    //     title: 'Adjustment List',
    //     itemId: '/transaction?id=14',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("finance_crosscheck_ongkir_cash")){
    //   subnav.push(
    //   {
    //     title: 'Crosscheck Ongkir Cash',
    //     itemId: '/reportongkircash',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("finance_crosscheck_marketplace_expense")){
    //   subnav.push(
    //   {
    //     title: 'Crosscheck Marketplace Expense',
    //     itemId: '/reportmarketplacetrx',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("finance_data_marketplace_expense")){
    //   subnav.push(
    //   {
    //     title: 'Data Marketplace Expense',
    //     itemId: '/reportmarketplace',
    //   }
    //   );
    // }
    // 
    if(this.state.accessobj.includes("finance_fixed_assets")){
      subnav.push(
      {
        title: 'Fixed Assets',
        itemId: '/masterasset',
      }
      );
    }
    // 
    // if(this.state.accessobj.includes("finance_value_per_warehouse")){
    //   subnav.push(
    //   {
    //     title: 'Value Per Warehouse',
    //     itemId: '/warehousevalue',
    //   }
    //   );
    // }
    // 
    // if(this.state.accessobj.includes("finance_ledger")){
    //   subnav.push(
    //   {
    //     title: 'Ledger',
    //     itemId: '/ledger',
    //   }
    //   );
    // }
    //  
    if(subnav.length > 0){ 
      menupro.push({
      title: 'Finances',
      itemId: 'k',
      elemBefore: () => <Money />,
      subNav: subnav
      });
      
      subnav = [];
    }
    
    if(this.props.login.id_user == 1 || this.props.login.id_user == 3){
      subnav.push({
        title: 'User Management',
        itemId: '/masteruser',
      }); 
      subnav.push({
        title: 'Holiday Dates',
        itemId: '/masterholiday',
      }); 
      
      menupro.push(
        {
          title: 'Settings',
          itemId: 'y',
          elemBefore: () => <Configure />,
          subNav: subnav,
        },
      );
      subnav = [];
    }
    
    this.setState({menuretail: menuretail.concat(menupro), menupro: menupro});
  }
  
  render(){
    return (
      <>
      <Tabs className="sidebar-tabs" alignControls="center" activeIndex={this.state.activeTab} onActive={(tab) => {this.setState({activeTab: tab})}}>
       { /*
        <Tab className="tab" title="Retail" key={0} index={0} active={false}>
          <Box align="center" pad={{top:"medium"}}>
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/home'})} icon={<Dashboard />} label="Dashboard" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/category'})} icon={<CatalogOption />} label="Category" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/item?type=product'})} icon={<CatalogOption />} label="Catalogue" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/item?type=packaging material'})} icon={<CatalogOption />} label="Packaging Material" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/item?type=consumable'})} icon={<CatalogOption />} label="Consumable" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/salesorder'})} icon={<Basket />} label="Sales" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/customer'})} icon={<Folder />} label="Datas" size="small" />
            <Anchor href="#" icon={<Ad />} label="Marketing" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/transaction'})} icon={<Money />} label="Finances" size="small" />
            <Anchor href="#" icon={<BarChart />} label="Stats & Reports" size="small" />
            <Anchor href="#" icon={<Configure />} label="Settings" size="small" />
            <Anchor href="#" icon={<Clipboard />} label="Working Report" size="small" />
          </Box>
        </Tab>
        <Tab className="tab" title="Production" key={1} index={1} active={false}>
          <Box align="center" pad={{top:"medium"}}>
            <Anchor href="#" icon={<Folder />} label="Datas" size="small" />
            <Anchor href="/masterproductionitem?type=material" icon={<Flows />} label="Material" size="small" />
            <Anchor href="/masterproductionitem?type=part" icon={<Flows />} label="Part" size="small" />
            <Anchor href="/masterproductionitem?type=packaging material" icon={<Flows />} label="Packaging Material" size="small" />
            <Anchor href="/masterproductionitem?type=consumable" icon={<Flows />} label="Consumable" size="small" />
            <Anchor href="/masterproduct" icon={<Flows />} label="Product" size="small" />
            <Anchor href="#" onClick={() => this.props.history.replace({pathname: '/productionbatch'})} icon={<Cubes />} label="Production" size="small" />
            <Anchor href="#" icon={<Basket />} label="Sales" size="small" />
            <Anchor href="#" icon={<Deliver />} label="Purchase" size="small" />
          </Box>
        </Tab>
        */ }
        <Tab className="tab" title="Retail" key={0} index={0}>
          <Box pad={{top:"medium"}}>
              <Navigation
                // you can use your own router's api to get pathname
                activeItemId={this.state.activeMenu}
                onSelect={({itemId}) => {
                  if(itemId.toString().length > 1){
                    this.props.history.replace(itemId);
                  }
                }}
                items={this.state.menuretail}
              />
          </Box>
        </Tab>
        { this.state.menupro.length > 0 && 1 == 2 &&
        <Tab className="tab" title="Production" key={1} index={1}>
          <Box pad={{top:"medium"}}>
              <Navigation
                activeItemId={this.state.activeMenu}
                onSelect={({itemId}) => {
                  if(itemId.toString().length > 1){
                    this.props.history.replace(itemId);
                  }
                }}
                items={this.state.menupro}
              />
          </Box>
        </Tab>
        }
        
      </Tabs>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // saveLogin: id_user => {
    //   dispatch(saveLogin(id_user));
    // }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
